// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-evodevo-js": () => import("./../../../src/pages/projects/evodevo.js" /* webpackChunkName: "component---src-pages-projects-evodevo-js" */),
  "component---src-pages-projects-hkdb-js": () => import("./../../../src/pages/projects/hkdb.js" /* webpackChunkName: "component---src-pages-projects-hkdb-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-projects-mcm-js": () => import("./../../../src/pages/projects/mcm.js" /* webpackChunkName: "component---src-pages-projects-mcm-js" */),
  "component---src-pages-projects-portfolio-js": () => import("./../../../src/pages/projects/portfolio.js" /* webpackChunkName: "component---src-pages-projects-portfolio-js" */),
  "component---src-pages-thanks-js": () => import("./../../../src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */),
  "component---src-pages-toolbox-js": () => import("./../../../src/pages/toolbox.js" /* webpackChunkName: "component---src-pages-toolbox-js" */)
}

